/* Theming */
@import url("https://fonts.googleapis.com/css2?family=Cardo&family=Josefin+Sans:wght@200;600&display=swap");

:root {
  --white: #f9f9f9;
  --black: #36383f;
  --gray: #d8e1ed;
} /* variables*/

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: var(--white);
  font-family: "Josefin Sans", sans-serif;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}

/* Header */
.header {
  background-color: #f9f9f9;
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
/* Logo */
.logo {
  display: inline-block;
  color: black;
  font-size: 2rem;
  margin-left: 10px;
  font-family: "Cardo", serif;
}

/* Nav menu */
.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #f9f9f9;
  overflow: hidden;
  margin-top: 2.6rem;
}
.menu a {
  display: block;
  padding: 30px;
  color: black;
}
.menu a:hover {
  background-color: var(--gray);
}
.nav {
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.menu {
  /* margin-top: 0.1rem; */
}

/* Menu Icon */
.hamb {
  cursor: pointer;
  float: right;
  padding: 1rem 1rem;
} /* Style label tag */

.hamb-line {
  background: black;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
} /* Style span tag */

.hamb-line::before,
.hamb-line::after {
  background: black;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.5s ease-out;
  width: 100%;
}
.hamb-line::before {
  top: 5px;
}
.hamb-line::after {
  top: -5px;
}

.side-menu {
  display: none;
} /* Hide checkbox */

/* Toggle menu icon */
.side-menu:checked ~ nav {
  max-height: 100%;
  max-width: 100%;
}

.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

.mainContent {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.content {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

#about {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
  font-size: 1.5rem;
  padding-top: 0;
  margin-left: 2rem;
}

.aboutPhoto {
  width: 100%;
  height: auto;
  margin-right: 2rem;
}

#andyPortrait {
  padding-left: 0;
  padding-top: 0;
  width: 100%;
  height: auto;
}

.aboutLeftCol {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 80%;
  margin-right: 1rem;
  /* margin-top: 10%; */
  overflow-y: scroll;
  padding-top: 0;
  margin-bottom: 6rem;
}

.aboutRightCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  /* margin-top: 10%; */
  overflow-y: scroll;
  padding-top: 0;
}

.aboutHeader {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.aboutYear {
  font-size: 1.25rem;
  margin-left: 3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.aboutRightCol p {
  margin-left: 6rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

#footnote {
  margin-left: 0;
}

.collectionImage {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 80%;
  height: auto;
  object-fit: cover;
  padding: 1rem;
  padding-top: 10%;
  margin: auto;
}
