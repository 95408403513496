/* Responsiveness */
@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 0;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  body {
    height: 100%;
  }

  #root {
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding: 0;
    margin: 0;
    padding-top: 8%;
  }

  .link {
    color: black;
  }

  .active {
    color: #8b1c1c;
  }

  .nav {
    max-height: 100%;
    top: 0;
    position: static;
    width: fit-content;
    background-color: #f9f9f9;
    color: black;
    padding-top: 0;
    margin-top: 0;
  }

  header#sideNav.header {
    position: static;
    height: 100%;
    padding: 1rem 0.5rem;
    background-color: #f9f9f9;
    color: black;
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: 1.25rem;
    margin-left: 1rem;
    padding-top: 0;
  }

  a {
    font-weight: 600;
  }

  .navParent {
    width: 20%;
    display: flex;
    justify-content: flex-start;

    padding-top: 0;
  }

  nav {
    margin-top: 0;
    padding-top: 0;
  }

  .header a,
  .nav a {
    color: black;
  }

  /* .nav a:active {
    color: #8b1c1c;
  } */

  .nav a.active {
    color: #8b1c1c;
  }

  .nav a:hover {
    color: #8b1c1c;
  }

  .menu a:hover {
    background-color: transparent;
  }

  .menu a {
    padding: 0.4rem;
    padding-left: 0;
  }

  .nonArtLinks {
    margin-top: 2rem;
  }

  a.logo {
    margin-left: 0;
    margin-bottom: 1rem;
    font-size: 3.5rem;
    padding-top: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;

    padding: 0;
    margin: auto;

    margin-right: 5rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    overflow-x: auto;
    align-items: flex-start;
    padding-top: 0;
    margin-top: 0;
  }

  .innerImages {
    display: flex;
    height: 80%;
    width: 100%;
    justify-content: flex-start;
    padding-left: 20%;
    padding-right: 50%;
  }

  .collectionImage {
    flex-shrink: 0;
    width: auto;
    height: 100%;
    margin-right: 20rem;
    padding-top: 0;
  }

  #about {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;

    justify-content: center;
    font-size: 1.5rem;
    padding-top: 0;
    margin-bottom: 3%;
    margin-left: 2rem;
    margin-right: 2rem;

    overflow-y: scroll;
    overscroll-behavior: none;
  }

  .aboutPhoto {
    width: 100%;
    height: auto;
    margin-right: 2rem;
  }

  #andyPortrait {
    padding-left: 0;
    padding-top: 0;
    width: 100%;
    height: auto;
  }

  .aboutLeftCol {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    width: 40%;
    margin-right: 1rem;
    padding-top: 0;
    overflow-y: visible;
  }

  .aboutRightCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
    padding-top: 0;
    margin-left: 3rem;
    overflow-y: visible;
  }

  .aboutHeader {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  .aboutYear {
    font-size: 1.25rem;
    margin-left: 3rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .aboutRightCol p {
    margin-left: 6rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  #footnote {
    margin-left: 0;
  }

  .hamb {
    display: none;
  }

  .spinner {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 auto;
  }

  .spinner-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #000;
    animation: spinner-rotate 1s infinite linear;
  }

  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
