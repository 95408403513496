.form-style-1 {
  margin: 10px auto;
  max-width: 80%;
  padding: 20% 12px 10px 20px;
  font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
.form-style-1 li {
  padding: 0;
  display: block;
  list-style: none;
  margin: 10px 0 0 0;
}
.form-style-1 label {
  margin: 0 0 3px 0;
  padding: 0px;
  display: block;
  font-weight: bold;
}
.form-style-1 input[type="text"],
.form-style-1 input[type="date"],
.form-style-1 input[type="datetime"],
.form-style-1 input[type="number"],
.form-style-1 input[type="search"],
.form-style-1 input[type="time"],
.form-style-1 input[type="url"],
.form-style-1 input[type="email"],
textarea,
select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #bebebe;
  padding: 7px;
  margin: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
}
.form-style-1 input[type="text"]:focus,
.form-style-1 input[type="date"]:focus,
.form-style-1 input[type="datetime"]:focus,
.form-style-1 input[type="number"]:focus,
.form-style-1 input[type="search"]:focus,
.form-style-1 input[type="time"]:focus,
.form-style-1 input[type="url"]:focus,
.form-style-1 input[type="email"]:focus,
.form-style-1 textarea:focus,
.form-style-1 select:focus {
  -moz-box-shadow: 0 0 8px #88d5e9;
  -webkit-box-shadow: 0 0 8px #88d5e9;
  box-shadow: 0 0 8px #88d5e9;
  border: 1px solid #88d5e9;
}
.form-style-1 .field-divided {
  width: 49%;
}

.form-style-1 .field-long {
  width: 100%;
}
.form-style-1 .field-select {
  width: 100%;
}
.form-style-1 .field-textarea {
  height: 100px;
}
.form-style-1 input[type="submit"],
.form-style-1 input[type="button"] {
  background: #4b99ad;
  padding: 8px 15px 8px 15px;
  border: none;
  color: #fff;
}
.form-style-1 input[type="submit"]:hover,
.form-style-1 input[type="button"]:hover {
  background: #4691a4;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.form-style-1 .required {
  color: red;
}

@media (min-width: 768px) {
  .form-style-1 {
    margin: 10px auto;
    max-width: 60%;
    padding: 20px 12px 10px 20px;
    font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  }
  .form-style-1 li {
    padding: 0;
    display: block;
    list-style: none;
    margin: 10px 0 0 0;
  }
  .form-style-1 label {
    margin: 0 0 3px 0;
    padding: 0px;
    display: block;
    font-weight: bold;
  }
  .form-style-1 input[type="text"],
  .form-style-1 input[type="date"],
  .form-style-1 input[type="datetime"],
  .form-style-1 input[type="number"],
  .form-style-1 input[type="search"],
  .form-style-1 input[type="time"],
  .form-style-1 input[type="url"],
  .form-style-1 input[type="email"],
  textarea,
  select {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #bebebe;
    padding: 7px;
    margin: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: none;
  }
  .form-style-1 input[type="text"]:focus,
  .form-style-1 input[type="date"]:focus,
  .form-style-1 input[type="datetime"]:focus,
  .form-style-1 input[type="number"]:focus,
  .form-style-1 input[type="search"]:focus,
  .form-style-1 input[type="time"]:focus,
  .form-style-1 input[type="url"]:focus,
  .form-style-1 input[type="email"]:focus,
  .form-style-1 textarea:focus,
  .form-style-1 select:focus {
    -moz-box-shadow: 0 0 8px #88d5e9;
    -webkit-box-shadow: 0 0 8px #88d5e9;
    box-shadow: 0 0 8px #88d5e9;
    border: 1px solid #88d5e9;
  }
  .form-style-1 .field-divided {
    width: 49%;
  }

  .form-style-1 .field-long {
    width: 100%;
  }
  .form-style-1 .field-select {
    width: 100%;
  }
  .form-style-1 .field-textarea {
    height: 100px;
  }
  .form-style-1 input[type="submit"],
  .form-style-1 input[type="button"] {
    background: #4b99ad;
    padding: 8px 15px 8px 15px;
    border: none;
    color: #fff;
  }
  .form-style-1 input[type="submit"]:hover,
  .form-style-1 input[type="button"]:hover {
    background: #4691a4;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  .form-style-1 .required {
    color: red;
  }
}
